.todo-table-list-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 10px;

    form {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        position: relative;
        overflow: visible;
        padding-top: 30px;

        .todo-table-btn-edit {
            position: absolute;
            top:5px;
            right:5px;
            display: inline;
        }

        button.todo-table-btn {
            border: 1px solid #bababa;
            background-color: #FFFFFF;
            color: #333333;
            width: 200px;
            height: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            overflow: visible;

            
        }
        button.todo-table-btn-current {
            border: 1px solid #333333;
        }
    }
}

.todo-col-container {
    display: flex;
    gap:10px;
    //border:1px solid #BABABA;

    .todo-col {
        width: 100%;
        border: 1px solid #BABABA;
        background-color: #eeeeee;
        min-height: 600px;

        .todo-item-label {
            padding:20px 5px;
            background-color: $white;
            border-bottom:1px solid #BABABA;  
        }

        .todo-item {
            border:1px solid #BABABA;
            min-height: 200px;
            margin:5px; 
            background-color: $white;

            .todo-handle {
                display: flex;
                justify-content: flex-end;
                i {
                    border: 1px solid #BABABA;
                    margin: 3px;
                    padding: 5px;
                    font-size: 12px;
                    color: #BABABA;
                }
            }

            .todo-title {
                border: 1px dotted #fdfdfd;
                padding: 5px;
                margin: 0 5px; 
                font-size: 20px;
                font-weight: bold;
            }

            .todo-content {
                border: 1px dotted #fdfdfd;
                padding: 5px;
                margin: 0 5px 5px;
            }

            .todo-data {
                padding: 10px;
                font-size: 12px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }

            .todo-assigned-to {
                //border-top: 1px solid #bababa;
                width:100%;
                text-align: right;
                font-size: 12px;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;

                .info-email {
                    padding: 5px;
                    display: flex;
                    align-items: center;
                    gap: 3px;
                }
            }

            .todo-created {
                border-top: 1px solid #bababa;
                font-size: 12px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;

                .info-email {
                    padding: 5px;
                }
            }

            .todo-info {
                border-top: 1px solid #bababa;
                font-size: 12px;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-end;

                .info-email {
                    padding: 5px;
                    display: flex;
                    align-items: center;
                    gap: 3px;
                }
                .info-date {
                    padding: 2px 5px 5px 5px;
                    display: flex;
                    align-items: center;
                    gap: 3px;
                }
            }
        }
    }

    .todo-col[col=to-do] {
        border: 1px solid #BABABA;

        .todo-item-label {
            border-bottom:1px solid #BABABA;  
        }
        .todo-item {
            border:1px solid #BABABA;
        }
    }

    .todo-col[col=in-progress] {
        border: 1px solid #548eda;

        .todo-item-label {
            border-bottom:1px solid #548eda;
            color: #548eda;
            background-color: rgba(84, 142, 218, 0.1); 
        }
        .todo-item {
            border:1px solid #548eda;
        }
    }

    .todo-col[col=completed] {
        border: 1px solid #42d155;

        .todo-item-label {
            border-bottom:1px solid #42d155;
            color: #42d155;
            background-color: rgba(66, 209, 85, 0.1); 
        }
        .todo-item {
            border:1px solid #42d155;
        }
    }

    .todo-col[col=blocked] {
        border: 1px solid #e04242;

        .todo-item-label {
            border-bottom:1px solid #e04242;
            color: #e04242;
            background-color: rgba(224, 66, 66, 0.1); 
        }
        .todo-item {
            border:1px solid #e04242;
        }
    }
}

.todo-edit {
    display:none;
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    overflow: auto;
    z-index: 10;
    background-color: $white;
    padding: 20px;

    .todo-edit-close {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
    }

    .todo-files-container {
        position:relative;
        padding:10px;

        .todo-files {
            display:flex;
            gap:10px;
            flex-wrap:wrap;
            padding:10px;
            margin-top:10px; 

            .todo-file {
                position:relative;
                display:flex;
                flex-direction:column;
                align-items:center;
                justify-content:center;
                overflow:visible;

                .file-trash {
                    position:absolute;
                    top:0;
                    right:0;
                    font-size:12px;
                    border: 1px solid $black;
                    z-index: 20;
                    padding:5px;
                    background-color: $white;
                }

                .file-img {
                    img {
                        width:100px;
                    }
                }

                .file-name {
                    padding:3px;
                    font-size: 11px;
                }

                .file-size {
                    padding:3px;
                    font-size: 11px;
                }
            }
        }
    }
}
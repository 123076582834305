.main {
    position: relative;
    margin: 35px 10px 55px 203px;
    transition: margin .1s;
    /*background-color: $white;*/
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 4px;
    padding: 30px;
    overflow: visible;
    font-size: $fontsize;
}

.main-top-btn-container {

    position: relative;
    display: flex;
    gap:10px;
    align-items: center;
    margin-top: 10px;

    .main-title-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        

        .main-icon {
            font-size: 32px;
            border: 1px solid #bababa;
            padding: 10px;
            background-color: #ffffff;
        }

        .main-title {
            font-weight: bold;
            font-size: 22px;
        }
    }

    .projecst-btn {
        padding: 5px 10px;
        border: 1px solid $grey;
        background-color: $white;
        color: $black;
        cursor:pointer;
        transition: border .3s, color .3s, background .3s;
        white-space: nowrap;

        &:hover {
            border: 1px solid $black;
            background-color: $black;
            color: $white;
            transition: border .3s, color .3s, background .3s;
        }
    }

    .how-it-works-btn {
        padding: 5px 10px;
        border: 1px solid $grey;
        background-color: $white;
        color: $black;
        cursor:pointer;
        transition: border .3s, color .3s, background .3s;
        white-space: nowrap;

        &:hover {
            border: 1px solid $black;
            background-color: $black;
            color: $white;
            transition: border .3s, color .3s, background .3s;
        }
    }

    .main-top-datetimetime {
        width: 100%;
        text-align: right;
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: flex-end;
        font-size: 32px;
        text-transform: capitalize;
    }
}

.alert-icon {
    color: #ec3b3b;
}

body {
    margin: 0;
    padding: 0;
    //font-family: 'Open Sans Condensed', sans-serif;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 14px;
    color: $black;
    background-color: #e9e8e8;
    font-weight: 200;
}

body.theme-blue {
    background-color: rgb(142, 196, 238);
    header {
        background-color: rgb(142, 196, 238);
    }
    .footer {
        background-color: rgb(142, 196, 238);
    }
}

body.theme-red {
    background-color: #ec656c;
    header {
        background-color: rgb(236, 101, 108);
    }
    .footer {
        background-color: rgb(236, 101, 108);
    }
}

body.theme-red-black {
    /*background-color: #ec656c;*/
    background: rgb(236,101,108);
    background: linear-gradient(90deg, rgba(236,101,108,1) 18%, rgba(51,51,51,1) 75%, rgba(51,51,51,1) 100%);

    header {
        /*background-color: rgb(236, 101, 108);*/
        background: rgb(236,101,108);
        background: linear-gradient(90deg, rgba(236,101,108,1) 18%, rgba(51,51,51,1) 75%, rgba(51,51,51,1) 100%);
        color: #ffffff;
    }

    .footer {
        /*background-color: rgb(236, 101, 108);*/
        background: rgb(236,101,108);
        background: linear-gradient(90deg, rgba(236,101,108,1) 18%, rgba(51,51,51,1) 75%, rgba(51,51,51,1) 100%);
        color: #ffffff;
    }

    .login-container {
        background-color: #ec656c!important;
    }
}

body.theme-yellow {
    background-color: rgb(223, 214, 91);

    header {
        background-color: rgb(223, 214, 91);
    }

    .footer {
        background-color: rgb(223, 214, 91);
    }
}

body.theme-yellow-black {
    /*background-color: #ec656c;*/
    background: rgb(223, 214, 91);
    background: linear-gradient(90deg, rgb(223, 214, 91) 18%, rgba(51,51,51,1) 75%, rgba(51,51,51,1) 100%);

    header {
        /*background-color: rgb(236, 101, 108);*/
        background: rgb(223, 214, 91);
        background: linear-gradient(90deg, rgb(223, 214, 91) 18%, rgba(51,51,51,1) 75%, rgba(51,51,51,1) 100%);
        color: #ffffff;
    }

    .footer {
        /*background-color: rgb(236, 101, 108);*/
        background: rgb(223, 214, 91);
        background: linear-gradient(90deg, rgb(223, 214, 91) 18%, rgba(51,51,51,1) 75%, rgba(51,51,51,1) 100%);
        color: #ffffff;
    }

    .login-container {
        background-color: rgb(223, 214, 91)!important;
    }
}

body.theme-orange {
    background-color: rgb(223, 170, 91);

    header {
        background-color: rgb(223, 170, 91);
    }

    .footer {
        background-color: rgb(223, 170, 91);
    }
}

body.theme-purple {
    background-color: rgb(190, 67, 221);

    header {
        background-color: rgb(190, 67, 221);
    }

    .footer {
        background-color: rgb(190, 67, 221);
    }
}

body.theme-green {
    background-color: rgb(77, 224, 85);

    header {
        background-color: rgb(77, 224, 85);
    }

    .footer {
        background-color: rgb(77, 224, 85);
    }
}

body.nav-closed {

    .main {
        margin: 35px 10px 55px 58px;
        transition: margin .1s;
    }
}

h2 {
    margin: 0 0 0 0;
    &::first-letter {
        text-transform: uppercase;
    }
}

.main-return {
    display:flex;
    align-items: center;
    justify-content: flex-start;

    a {
        padding: 0 10px 0 0;
        color: inherit;
        font-size: 20px;
    }
}

.main-tab-container {
    position:relative;
    display: flex;
    min-height: 80px;
    align-items: flex-end;

    .main-tab-btn {
        width: 100%;
        padding: 10px;
        cursor: pointer;
        margin: 0 2px;
        border: 1px solid $grey;
        border-bottom: 0;
        z-index: 2;
        background-color: $white;
        border-radius: 3px 3px 0 0;
        transition: padding .3s;
        color: $grey;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;

        &::first-letter {
            text-transform: uppercase;
        }
    }

    .main-tab-btn-active {
        padding: 20px 10px;
        margin-bottom: -1px;
        font-weight: bold;
        transition: padding .3s;
        color: $black;
    }
}

.main-tab-content {
    position: relative;
    display:none;
    margin: 0 2px;
    border:1px solid $grey;
    z-index: 1;
    padding: 10px;
    background-color: $white;

    .tab-content-view {
        position: relative;

        .tab-content-view-btn-container {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 5px;
            padding: 5px 0;
        }

        .tab-content-view-btn {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            cursor: pointer;
            gap: 5px;
            padding: 5px;
            border: 1px solid $grey;
        }
    }

    .tab-content-view-hidden {
        display: none;
    }

    .main-content-edit-top-reference {
        padding:5px;
        font-size: 22px;
        text-align:right;
    }

    .main-content-edit-top-info {
        display:flex;

        .content-edit-top-info-name {
            width: 100%;
            padding:5px;
            font-size:20px;
        }

        .content-edit-top-info-address {
            width: 100%;
            padding:5px;
            font-size:20px;
            text-align: right;
        }
    }

    .params-theme-color-container {
        display:flex;
        flex-wrap: wrap;
        gap:10px;
    }

    .params-theme-color-item {
        display:flex;
        align-items:center;
        justify-content: flex-start;
        border:1px solid #bababa;
        padding:20px;

        .theme-color-item-radio {
            margin: 0 0 0 0;
            width:80px;
        }

        .theme-color-name {
            margin: 0 0 0 0;
            width:100px;
            text-align: left;
        }

        .color-item-block-white {
            width: 30px;
            height: 30px;
            border:1px solid #BABABA;
            background-color: #FFFFFF;
            margin: 0 3px;
        }

        .color-item-block-default {
            width: 30px;
            height: 30px;
            border:1px solid #BABABA;
            background-color: #BABABA;
            margin: 0 3px;
        }
        .color-item-block-blue {
            width: 30px;
            height: 30px;
            border:1px solid #BABABA;
            background-color: #0390fc;
            margin: 0 3px;
        }
        .color-item-block-black {
            width: 30px;
            height: 30px;
            border:1px solid #BABABA;
            background-color: #333333;
            margin: 0 3px;
        }
        .color-item-block-red {
            width: 30px;
            height: 30px;
            border:1px solid #BABABA;
            background-color: #eb3a34;
            margin: 0 3px;
        }
        .color-item-block-yellow {
            width: 30px;
            height: 30px;
            border:1px solid #BABABA;
            background-color: #dddb43;
            margin: 0 3px;
        }
        .color-item-block-orange {
            width: 30px;
            height: 30px;
            border:1px solid #BABABA;
            background-color: #ddb443;
            margin: 0 3px;
        }

        .color-item-block-purple {
            width: 30px;
            height: 30px;
            border:1px solid #BABABA;
            background-color: #be43dd;
            margin: 0 3px;
        }

        .color-item-block-green {
            width: 30px;
            height: 30px;
            border:1px solid #BABABA;
            background-color: #4de055;
            margin: 0 3px;
        }

        .color-item-block-wave {
            width: 30px;
            height: 30px;
            border:1px solid #BABABA;
            background-color: #ffffff;
            margin: 0 3px;
            background: linear-gradient(315deg, rgba(101,0,94,1) 3%, rgba(60,132,206,1) 38%, rgba(48,238,226,1) 68%, rgba(255,25,25,1) 98%);
        }
    }
}

.subcontractor-edition-flex-container {
    display:flex;
    gap:10px;

    .subcontractor-edition-flex {
        width: 50%;
        border:1px solid #ddd;
        padding: 10px;
    }
}

.main-tab-content-visible {
    display: block;
}

.main-view-comment-container {
    border:1px solid #bababa;
    padding:10px;
}

.main-view-custom-field-container {
    border:1px solid #bababa;
    padding:10px;
    .main-view-custom-field {
        margin: 0 0 5px 0;
        .view-custom-field-label {
            font-weight:bold;
        }
    }
}

.btn-invoice-create {
    width: auto;
}

.btn-auto {
    width:auto;
    min-width:auto;
    margin:auto;
}

.state-ball {
    width: 25px;
    height: 25px;
    position: relative;
    margin: 0 10px 0 0;
    border-radius: 3px;
}

.state-ball-quotation {
    background-color: $grey;
}

.state-ball-invoice,
.state-ball-project {
    background-color: #e49631;
}

.state-ball-invoice-close,
.state-ball-project-close {
    background-color: #7af04b;
}

.state-ball-cancelled {
    background-color: #eb4832;
}

.container-years-nav {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 0;
    width: 100%;
    gap:5px;

    form {

        padding:0 0 0 0;

        button.year-nav-item {
            border:1px solid $grey;
            padding: 5px 10px;
            font-size: $fontsize;
            background-color:$white;
            cursor:pointer; 
            color: $black;
            display: block;
        }

        button.year-nav-item-selected {
            border: 1px solid $white;
            color : $white;
            background-color:$black;
            padding: 10px 10px;
        }
    }
}

.bike-power-row-active, .car-power-row-active {
    border: 1px solid red;
    background-color: rgba(255, 0, 0, 0.2);
}
.nav {
    position: fixed;
    top: 30px;
    bottom: 30px;
    left: 0;
    width: 200px;
    transition: width .1s;
    overflow-x : auto;
    font-size: $fontsize;
    z-index: 800;

    ul {
        margin:0;
        padding:0;
        list-style:none;

        li {

            .nav-parent {
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: right;
                background-color: $white;
                margin: 5px;
                border-radius: 4px;
                padding: 0 0;
                transition: border .5s;

                a.text {
                    display: block;
                    width: calc(100% - 40px);
                    color: inherit;
                    text-decoration: none;
                    padding: 5px 10px;
                    border-right: 1px solid #e9e8e8;
                    transition: border .5s, font-weight .5s;

                    &::first-letter {
                        text-transform: uppercase;
                    }
                }

                a.icon {
                    text-align: center;
                    width: 40px;
                    display: block;
                    color: inherit;
                    text-decoration: none;
                    padding: 5px 10px;
                    background-color: $white;
                    color: $black;
                    border-radius: 0;
                }

                a.nav-parent-children-project {
                    font-size:22px;
                }
            }

            .nav-parent-with-children {
                border-radius: 4px 4px 0 0;
                /*border-bottom: 1px dotted #BABABA;*/
            }

            .nav-children {
                margin-top: -5px;
                

                ul {
                    margin:0 5px 0 5px;
                    //padding:0 0 5px 0;
                    background-color: rgba(255, 255, 255, 0.5);
                    padding:5px;
                    list-style:none;
                    border-radius: 0 0 4px 4px;

                    li {

                        display: flex;
                        align-items: center;
                        justify-content: center;
                        text-align: right;
                        background-color: #ffffff;
                        margin: 5px;
                        border-radius: 4px;
                        padding: 0 0;
                        transition: border .5s;

                        a.text {
                            display: block;
                            width: calc(100% - 40px);
                            color: inherit;
                            text-decoration: none;
                            padding: 5px 10px;
                            border-right: 1px solid #e9e8e8;
                            transition: border .5s, font-weight .5s;

                            &::first-letter {
                                text-transform: uppercase;
                            }
                        }

                        a.icon {
                            text-align: center;
                            width: 40px;
                            display: block;
                            color: inherit;
                            text-decoration: none;
                            padding: 5px 10px;
                            background-color: $white;
                            color: $black;
                            border-radius: 0;
                        }
                    }
                }
            }
        }

        li.nav-current {

            .nav-parent {
                border: 1px solid $black;
                margin: 5px 5px 5px 25px;
                transition: border .5s, margin .5s;
                background-color: $white;
                color: $black;

                a.text {
                    font-weight: bold;
                    border-right: 1px solid $black;
                    transition: border .5s, font-weight .5s;
                }

                a.icon {
                    background-color: $black;
                    color: $white;
                }
            }
        }

        /*li.nav-item-master {
            color: #912382;
            border: 1px solid #912382;

            a.text {
                border-right: 1px solid #912382;
            }

            a.icon {
                background-color: #912382;
                color: $white;
            }
        }*/

        li.nav-item-top-marg {
            margin-top:15px;
        }
    }
}

body.nav-closed {

    .nav {
        width: 55px;
        transition: width .1s;
    
        ul {
            li {

                .nav-parent {
                    a.text {
                        width: 0px;
                        visibility: hidden;
                        display: none;
                    }
                }

                .nav-children {

                    ul {
                        margin: 0 5px 0 5px;

                        a.text {
                            width: 0px;
                            visibility: hidden;
                            display: none;
                        }

                        a.icon {
                            border-radius: 4px;
                        }
                    }
                }
            }
            li.nav-current {
                margin: 5px 5px 5px 5px;
                background-color: $black;
                color: $white;
                a.text {
                    width: 0px;
                    visibility: hidden;
                    display: none;
                }
            }

            li.nav-item-top-marg {
                margin-top:15px;
            }
        }
    }
}




.pricelist-main {
    position:relative;

    .pricelist-topnav {
        display:flex;
        flex-wrap:wrap;
        justify-content:flex-end;

        .pricelist-anchor-btn {
            display:flex;
            align-items:center;
            justify-content:center;
            border:1px solid #BABABA;
            margin: 3px;
            width:auto;
            cursor:pointer;
            font-size:13px;
            background-color: $white;

            .btn-icon {
                padding:5px;
                border-right:1px solid #BABABA;
            }

            .btn-text {
                padding:5px;
            }
        }
    }
    .pricelist-topnav-fixed {
        position: fixed;
        right: 25px;
        top: 30px;
        z-index: 30;
    }
}
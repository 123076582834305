.slideshow-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999999;
    background-color: rgba(51, 51, 51, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .slideshow-close {
        position:absolute;
        top: 10px;
        right: 15px;
        cursor: pointer;
        color: $white;
        cursor: pointer;
        font-size: 30px;
    }

    .slideshow-prev {
        width: 100px;
        position: fixed;
        top: calc(50% - 50px);
        left: 5px;
        font-size: 70px;
        color: $white;
        cursor: pointer;
        transition: font-size .3s;
        z-index: 888;
        &:hover {
            font-size: 80px;
            transition: font-size .3s;
        }
    }

    .slideshow-img {
        width: 80%;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        height: calc(100% - 100px);
        margin-top: 25px;
        max-height: 70%;
    }

    .slideshow-next {
        width: 100px;
        position: fixed;
        top: calc(50% - 50px);
        right: 5px;
        font-size: 70px;
        color: $white;
        cursor: pointer;
        transition: font-size .3s;
        z-index: 888;
        &:hover {
            font-size: 80px;
            transition: font-size .3s;
        }
    }

    .slideshow-options {

        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 888;
        position: fixed;
        bottom: 10px;
        right: 5px;
        left:5px;

        .slideshow-rotate-left {

            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            color: $white;
            cursor: pointer;
            transition: font-size .3s;
            margin: 0 20px;
            &:hover {
                font-size: 30px;
                transition: font-size .3s;
            }

        }

        .slideshow-play {
            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            color: $white;
            cursor: pointer;
            transition: font-size .3s;
            margin: 0 20px;
            &:hover {
                font-size: 30px;
                transition: font-size .3s;
            }
        }


        .slideshow-rotate-right {

            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            color: $white;
            cursor: pointer;
            transition: font-size .3s;
            margin: 0 20px;
            &:hover {
                font-size: 30px;
                transition: font-size .3s;
            }

        }

    }
}
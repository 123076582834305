.calendar-main{
    position: relative;
}

.calendar-top {

    display:flex;
    align-items: center;
    margin: 0;
    position: relative;

    .link-cal{
        width: auto;
        text-align: center;
        margin: 0 3px;

        button {
            width: auto;
        }
    }


    form {
        display: block;
        position:relative;
        width: 100%;
        padding: 0;
    }

    .head-cal {
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        position: relative;

        select {
            border: 1px solid #bababa;
            background-color: $white;
            max-width: 100px;
            width: 100px;
            max-width: 100px;
            min-width: 100px;
            margin: 3px;
            padding: 5px;
            color: #bababa;
        }

        button {
            border: 1px solid #bababa;
            color: #bababa;
            background-color: $white;
            padding: 0 0;
            opacity: 1;
            transition: opacity 0.3s;

            width: 50px;
            max-width: 50px;
            min-width: 50px;
            margin: 3px;

            &:hover {
                opacity: 0.5;
                transition: opacity 0.3s;
            }
        }
    }
}




.calendar-week-day{
    border-top: 1px solid #bababa;
    border-bottom: 1px solid #bababa;
    border-left: 1px solid #bababa;
    display:flex;
    margin: 0 3px;
    background-color: $white;
    color: #bababa;


    .week-day{
        border-right: 1px solid #bababa;
        width: calc(100% / 7);
        height: auto;
        position: relative;
    }

    .week-day-number {
        padding: 1px 5px;
        margin: 5px;
        color: #bababa;
        float: left;
    }
}

.row-day{
    border-left: 1px solid #bababa;
    border-bottom: 1px solid #bababa;
    display:flex;
    min-height: 30px;
    margin: 0 3px;

    .js-cal-day {
        position:relative;

        .js-cal-day-weather {
            position: absolute;
            bottom: 3px;
            left: 3px;
            background-color: #bababa;
            padding: 0;
            border: 1px solid $black;
            img {
                width: 30px;
            }
        }
    }

    .empty-day{
        border-right: 1px solid #bababa;
        width: calc(100% / 7);
        height: 115px;
        background-color: #BABABA;
        position: relative;
    }

    .current-day{
        border-right: 1px solid #bababa;
        width: calc(100% / 7);
        height: 115px;
        position: relative;
        display: flex;

        /*background: repeating-linear-gradient(60deg, white, white 35px, #e9dbf8 35px, #e9dbf8 70px);
        min-height: 100%;*/
    }

    .day-number-flex {
        width:auto;
    }

    .day-events-items {
        width:100%;
        padding: 5px;
        max-height: 115px;
        overflow-x:hidden;
        overflow-y: auto;
    }
}

.day-events-flex {
    display: flex;
    flex-wrap: wrap;

    .event-cal-item {
        border: 1px solid #bababa;
        width: calc(20% - 10px);
        margin: 3px;
        text-align: center;
        flex:1 1 auto;
        max-width: calc(20% - 10px);
        background-color: $white;

        .cal-item-name {
            display:block;
            padding: 5px 3px;
            font-size: 18px;
            font-weight: bold;
        }

        .cal-item-comment {
            display:block;
            padding: 5px 3px;
            font-size: 16px;
        }

        .cal-item-address {
            padding: 3px 10px;
        }

        .cal-item-date {
            padding: 3px 10px;
            text-align: right;
            font-size: 26px;
            font-weight: lighter;
        }

        .cal-item-hours {
            position: relative;
            text-align: right;
            padding: 3px 10px;
            font-size: 36px;
            font-weight: bold;
            border-top: 1px dashed #BABABA;
        }
    }
}

.other-day{
    border-right: 1px solid #bababa;
    width: calc(100% / 7);
    height: 115px;
    position: relative;
    display: flex;
    background-color: $white;

    .day-number {
        display: block;
        float: left;
        text-decoration: none;

        .btn-cal-day-number {
            color: #bababa;
            border: 0;
            border-right: 1px solid #bababa;
            border-bottom: 1px solid #bababa;
            padding: 5px;
            margin: 0;
            background-color: $white;
            font-size: 13px;
        }
    }
}



.current-day {

    /*background-color: #ccbcdd;*/
    /*background-color: rgba(130, 98, 167, 0.2);*/
    background-color: $white;

    .current-day-link{

        display: block;
        float: left;
        text-decoration: none;


        .btn-cal-current-day-number {
            color: $white;
            padding: 5px;
            border: 0;
            border-right: 1px solid #bababa;
            border-bottom: 1px solid #bababa;
            margin: 0;
            background-color: #bababa;
            font-size: 18px;
            font-weight: bold;
        }
    }
}



.btn-cal {
    width: auto;
    padding: 5px 10px;
    border: 1px solid #bababa;
    background-color: $white;
    cursor: pointer;
    color: #bababa;
}

.event-cal {
    border: 1px solid #bababa;
    color: #bababa;
    background-color: $white;
    margin: 3px 5px;
    padding: 3px 5px;
    font-size: 13px;
}

.event-cal, .event-cal-current-day {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 2px;
    font-size: 13px;

    a {
        display: block;
        color: inherit;
        text-decoration: none;
    }

    .event-edit {
        width:100%;
        text-align: left;
        padding: 2px 3px;
    }

    .event-trash {
        width:auto;
        text-align: center;
        padding: 2px 3px;
    }
}

/*.event-cal-current-day {
    border: 1px solid #76559c;
    color: #76559c;
    background-color: $white;
    margin: 3px 5px;
    padding: 3px 5px;
    font-size: 13px;

}*/


/*.event-cal-current-day a {
    display: block;
    color: inherit;
    text-decoration: none;
}*/

.week-number {
    border-right:1px solid #bababa;
    width: 30px;
    background-color: $white;
    position: relative;
    color: #bababa;


    form {
        display: block;
        padding: 0;
        margin: 0;
        position:absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.btn-cal-week-number {
    width: 28px;
    display: block;
    border: 0;
    background-color: transparent;
    margin:0;
    padding: 0;
    cursor: pointer;
    font-size: 13px;
    margin: 0;
    padding: 0;
    border: 1px solid transparent;
    position:absolute;
    top: 2px;
    left: 0;
    right: 0;
    bottom: 2px;
    opacity: 1;
    transition: opacity .3s;
    color: #bababa;
    &:hover {
        opacity: 0.6;
        transition: opacity .3s;
    }

}


.cal-week-container {
    display:flex;
    margin: 0 3px;

    .cal-week-col {
        width:100%;
        border:1px solid #bababa; 
    }

    .cal-week-day {
        border-bottom:1px solid #bababa;
        display:flex;
        align-items:center;
        justify-content:center;
        padding: 10px;
    }

    .cal-week-day-current {
        border-bottom:1px solid #76559c;
        display:flex;
        align-items:center;
        justify-content:center;
        padding: 10px;
        color:#76559c;
    }

    .cal-week-day-hours-container {
        height:calc(100vh - 300px);
        overflow-x:hidden;
        overflow-y:auto;

        .hours-container {
            border-bottom:1px solid #bababa;
            height:100px;
            display:flex;
            align-items:center;

            .hours-item {
                border-right:1px solid #bababa;
                align-items:center;
                justify-content:center;
                height:100%;
                width: 40px;
                text-align:center;

                .hours {
                    border:1px solid #bababa;
                    margin: 3px;
                    font-size: 11px;
                }

                .hours-now {
                    border:1px solid #76559c;
                    color: #76559c;
                    margin: 3px;
                    font-size: 11px;
                }
            }


            .hours-events {
                max-height: 98px;
                width: calc(100% - 40px);
                overflow: auto;
            }
        }
    }
}

.cal-return {
    display: block;
    width: auto;
    padding: 10px;
    border: 1px solid #bababa;
    background-color: $white;
    cursor: pointer;
    text-decoration: inherit !important;
    color: inherit !important;
    margin: 0;

}

.day-off {
    border: 1px solid #55bd2d;
    color: #55bd2d;
    background-color: $white;
    margin: 3px 5px;
    padding: 3px 5px;
    font-size: 13px;
}

.btn-cal-day-number-day-off {
    color: #55bd2d;
    border: 0;
    border-right: 1px solid #55bd2d;
    border-bottom: 1px solid #55bd2d;
    padding: 5px;
    margin: 0;
    background-color: $white;
    font-size: 13px;
}

.day-off-bg {
    background: repeating-linear-gradient(60deg, white, white 35px, #d5fdc5 35px, #d5fdc5 70px);
    min-height: 100%;
}


.btn-cal-day-number-weekend {
    color: #BABABA;
    border: 0;
    border-right: 1px solid #BABABA;
    border-bottom: 1px solid #BABABA;
    padding: 5px;
    margin: 0;
    background-color: $white;
    font-size: 13px;
}

.day-is-weekend {
    /*background-color: rgba(186, 186, 186, 0.3);*/
    background-color: #f8f8f8;
}

/*.cal-modal-container {
    position: fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    z-index: 7777;
    background-color: rgba(160, 160, 160, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;

    .cal-modal {
        position:relative;
        width: 600px;
        height: auto;
        background-color: $white;
        padding: 10px;
    }
}*/

.js-cal-day {

    .cal-add-event {
        display: block;
        position: absolute;
        bottom: 0;
        right: 0;
        color: #bababa;
        border-left: 1px solid #bababa;
        border-top: 1px solid #bababa;
        padding: 5px;
        visibility: hidden;
        background-color: $white;
        z-index: 77;
    }

    &:hover {

        .cal-add-event {
            visibility: visible;
        }

    }
}
.map-full {
    height: calc(100vh - 200px);
    width: 100%;
    position: relative;
    overflow: hidden;

    border: 1px solid #BABABA;
}

.address-map {
    width: 100%;
    height: 400px;
    border-radius: 5px;;
}

.customer-map {
    width: 100%;
    height: 400px;
    border-radius: 5px;;
}

.leaflet-pane {
    z-index: 5 !important;
}
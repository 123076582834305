
.dashboard-stat-nav {
    display:flex;
    gap:10px;
    justify-content:flex-end;
    border-bottom: 1px dashed #bababa;
    padding-bottom: 20px;

    .dashboard-stat-nav-item {
        border:1px solid #bababa;
        padding:10px 10px;
        cursor:pointer;
        font-weight: bold;
        opacity: 1;
        transition: opacity .3s;

        &:hover {
            opacity: 0.8;
            transition: opacity .3s;
        }
    }

    .dashboard-stat-nav-item-selected {
        border: 1px solid #FFFFFF;
        color: #FFFFFF;
        background-color: #333333;
        padding: 10px 10px;
    }
}
.dashboard-container {
    display: flex;
    //flex-wrap: wrap;
    gap: 15px;

    .dashboard-col {
        width: 33.33%;
        min-height: 300px;
        min-width: 200px;
        padding: 0;
        text-align: center;

        .dashboard-row {
            border:1px solid #BABABA;
            margin: 15px 0;
            background-color:$white;
            transition: background 0.3s, border 0.3s;
            padding:10px;
            

            .row-header {
                position: relative;
                display: flex;
                min-height: 10px;
                align-items: center;
                justify-content: flex-end;
                padding: 0;
                margin-bottom: 5px;
                visibility: hidden;

                div {
                    border: 1px solid #BABABA;
                    color: #BABABA;
                    padding: 5px;
                    margin: 5px;
                    font-size: $fontsize;
                    border-radius: 4px;
                }

            }

            &:hover {
                background-color: #f1f1f1;
                transition: background 0.3s, border 0.3s;
                border-radius: 4px;
                border:1px solid transparent;

                .row-header {
                    visibility: visible;
                }
            }
        }

        .dashboard-row-no-border {
            border:1px solid transparent;
        }

        &:last-child {
            padding: 5px 0 0 0;
        }
    }
}
.dashboard-container-hidden {
    display:none;
}

.dashboard-stat-project-container {
    position:relative;
    width:100%;

    table {
        thead {
            tr {
                th {
                    padding: 0 10px;
                    text-transform: uppercase;
                }
                th.th-title-big {
                    font-size:18px;
                    text-align: left;
                    /*background-color: #333333;
                    color: #ffffff;*/
                }
            }
        }

        tbody {
            tr {
                td.td-project-percent-text {
                    width:80px;
                    color: #333333;
                    font-weight: bold;
                }
            }
        }
    }

    .dashboard-widget-stat-project-title {
        position:relative;
        padding:10px 0;
        font-size:18px;
        font-weight:bold;
        span {
            font-size:13px;
            font-weight:200; 
        }
    }

    .dashboard-widget-stat-project-percent-container {
        position: relative;

        .stat-project-percent-progress-bar {
            display:flex;
            align-items:center;
            width:100%;

            .project-percent-progress-bar-min {
                width:40px;
                text-align:center;
                font-size:12px;
                font-weight: 400;
            }

            .project-percent-progress-bar-max {
                width:40px;
                text-align:center;
                font-size:12px;
                font-weight: 400;
            }

            .project-percent-progress-bar-container {
                width:100%;
                border: 2px solid #bababa;
                border-radius:3px;
                overflow:hidden;
                height:50px;

                .project-percent-progress-bar-value {
                    background-color:#46a3e0cc;
                    height:48px;
                    /*border-radius:0 3px 3px 0;*/
                    border-right: 1px solid #bababa;
                }
            }
        }
    }
}
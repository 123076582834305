.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    //border: 1px solid #BABABA;
    background-color: #e9e8e8;
    z-index: 10;
    //color: #ffffff;
    font-size: 13px;
    padding: 0 10px;
}
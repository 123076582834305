.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #e9e8e8;
    z-index: 10;
    padding: 0 10px;

    div {
        margin: 0 5px;
        width: auto;
        white-space: nowrap;
    }

    .header-logo {
        width:100%;
        a {
            padding: 0 12px;
            color: #e9e8e8;
        }
    }
    .header-text {
        width:100%;
        a {
            padding: 0;
            color: #333333;
            font-size: 22px;
            font-weight: bold;
        }
    }

    .header-menu-mobile {
        width: 100%;
        text-align: left;
        font-size: 32px;
        display: none;
    }
}
@media all and (max-width:820px) {

    .login-container {
        .login-main {
            width: 80%;
        }
    }

    input, select, textarea, button {
        padding: 20px 10px;
        font-size: 16px;
    }

    label {
        font-size: 16px;
    }

    .nav {
        display:none;
        width: 100%;
        background-color: #FFFFFF;
        top:55px;

        ul {
            li {
                font-size: 20px;
                border-radius: 0;
                .text {
                    text-align: left;
                    padding: 10px;
                }
                /*&:first-child {
                    display:none;
                }*/
            }

            li.nav-current {
                margin: 5px 5px 5px 5px;
            }
        }
    }

    .main {
        margin: 55px 5px 55px 5px;
        padding: 20px 10px;
    }

    .header {
        font-size: 18px;
        height: auto;
        padding: 10px 10px;

        .header-menu-mobile {
            display: block;
        }
        .header-item-society-icon {
            display:none;
        }
        .header-item-society-name {
            display: none;
        }
        .header-item-user-icon {
            margin-right: 20px;
        }
        .header-item-user-name {
            display:none;
        }
    }

    .dashboard-container {
        flex-direction: column;

        .dashboard-col {
            width: 100%;
        }
    }

    .main-top-btn-container {
        flex-wrap: wrap;
    }

    .container-years-nav {
        flex-direction: column;
        gap: 5px;
        margin-top: 5px;

        form {
            width: 100%;

            button.year-nav-item {
                font-size: 18px;
            }
        }
    }

    .subcontractor-edition-flex-container {
        display:flex;
        gap:10px;
        flex-wrap:wrap;

        .subcontractor-edition-flex {
            width: 100%;
        }
    }

    .table-options {
        font-size: 18px;
        /*margin-top: 20px;*/
        width:100%;
        flex-direction: column;

        .table-actions {
            width: 100%;
            margin-top: 10px;
        }
        .table-option-search {
            width:100%;
            input {
                width: calc(100% - 40px);
                min-width: inherit;
                max-width: inherit;
                font-size: 18px;
            }
            button {
                width: 40px;
                font-size: 18px;
            }
        }
    }

    table.table-responsive {
        border: 0;
        font-size: 18px;
    }

    table.table-responsive thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    table.table-responsive tr {
        border-bottom: 3px solid #ddd;
        display: block;
        margin-bottom: .625em;
    }

    table.table-responsive td {
        border-bottom: 1px solid #ddd;
        display: block;
        font-size: 16px;
        text-align: right;
        padding: 10px 5px;
        min-height: 44px;

        .table-ball {
            float: right;
        }
    }

    table.table-responsive td::before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
    }

    table.table-responsive td:last-child {
        border-bottom: 0;
    }

    .weather-container {

        .weather-container-col {
            flex-wrap: wrap;

            .weather-col-today {
                width: 100%;
                max-height: inherit;

                .weather-today-items-min {
                    flex-wrap: wrap;
                }
            }

            .weather-col-other-today {
                width: 100%;
                max-height: inherit;

                .weather-items-other {
                    gap: 10px;

                    .weather-item {
                        width:100%;
                    }
                }
            }
        }
    }

    .table-ball-legend {
        width: 100%;
        flex-wrap: wrap;
        justify-content: center;

        .legend-item {
            width: calc(25% - 5px);
        }
    }

    .table-paginate {

        width: 100%;
        flex-wrap: wrap;
        justify-content: center;
        

        .paginate-item-per-page {
            width: 100%;

            select {
                width: calc(100% - 40px);
                max-width: inherit;
                min-width: inherit;
                font-size:18px;
            }
            button {
                width: 40px;
            }
        }

        .paginate-pages {
            flex-wrap: wrap;
            margin-top: 10px;

            .paginate-pages-info {
                font-size: 18px;
            }

            form {

                button.page-btn {
                    font-size: 18px;
                    padding:10px;
                }
            }
        }
    }

    .main {
        font-size: 16px;
    }

    .main-tab-container {
        flex-direction: column;
        margin: 10px 0 0 0;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        

        .main-tab-btn {
            border-radius: 0;
            display: block;
            text-align:center;
        }
    }

    .main-tab-content {
        margin: 0 0;
    }

    .option-table-container {
        flex-wrap: wrap;
        flex-direction: column;
        gap: 10px;

        a.btn {
            width: 100%;
            text-align: center;
        }

        form {
            width: 100%;

            button[type=submit] {
                width: 100%;
            }
        }
    }
}

@media all and (max-width: 480px) {
}
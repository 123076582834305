.table-options {
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .table-actions {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    a {
        display: block;
        border: 1px solid #BABABA;
        color: $black;
        padding: 5px;
        margin: 0 0 0 5px;
        background-color: $white;
        color: $black;
    }

    .table-option-search {
        display: flex;
        align-items: center;
        position: relative;

        input, button {
            display: block;
            padding: 5px;
            background-color: $white;
            color: $black;
            border: 1px solid #BABABA;
        }

        input {
            width: 200px;
            max-width: 200px;
            min-width: 200px;
        }

        button {
            width: auto;
            max-width: auto;
            min-width: auto;
            border-left: 0;
        }
    }

    .table-option-view {

        display: block;
        border: 1px solid #BABABA;
        color: $black;
        padding: 5px;
        margin: 0 0 0 5px;
        background-color: $white;
        color: $black;

    }
}

a.table-options-action-btn {
    display: block;
    border: 1px solid #BABABA;
    color: $black;
    padding: 5px;
    margin: 0 0 0 5px;
    background-color: $white;
    color: $black;
    font-size:14px;
}

.table-ball-legend {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 10px 0;
    gap: 5px;

    .legend-item {
        border: 1px solid #bababa;
        margin: 0;
        width: 70px;
        text-align: center;
        padding: 5px 0;
        background-color: $white;

        .legend-text {
            font-size: 12px;
            padding: 3px 0;
            margin-top: 5px;
            color: $black;
            border-top: 1px dashed #bababa;
        }
    }
}

.table-option-font-size-container {
    display:flex;
    gap:5px;
    margin-right:5px;

    .table-option-font-size {
        border:1px solid #bababa;
        background-color:#ffffff;
        padding:5px;
        font-size:9px;
        cursor:pointer; 
    }
}

.table-options-columns-container {
    display:none;

    .table-options-columns-label {
        position:relative;
        padding: 10px 0;
        font-size:16px;
        font-weight: 100;
    }

    .table-options-columns-items {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap:10px;
        justify-content: flex-start;
        padding:10px 0;
        margin-bottom:10px;

        .table-options-columns-item {
            border:1px solid #bababa;
            display: flex;
            align-items: center;
            background-color: #ffffff;
            font-size:12px;

            .table-options-columns-item-checkbox {

                border-right: 1px solid #bababa;
                padding: 5px;

            }
            .table-options-columns-item-text {
                padding: 0 5px;
            }
        }
    }
}

.table-option-columns-container {

    display:flex;
    gap:5px;
    margin-left:5px;

    .table-option-columns {
        border:1px solid #bababa;
        background-color:#ffffff;
        padding:5px;
        cursor:pointer; 
    }

    .table-option-columns-open {
        border:1px solid #555555;
        background-color:#555555;
        color: #ffffff;
    }
}

table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #BABABA;
    text-align: left;

    th {
        height: 50px;
        border: 1px solid #BABABA;

        &::first-letter {
            text-transform: uppercase;
        }

        .sort-container {
            display: flex;
            align-items: center;

            span {
                width: 100%;
                padding: 5px;

                &::first-letter {
                    text-transform: uppercase;
                }
            }
    
            .th-sort {
                width: auto;
    
                form {
                    position: relative;
                    padding: 2px;
    
                    button {
                        padding: 3px;
                        margin:0;
                        font-size: 9px;
                        border: 1px solid #BABABA;
                        background-color: $white;
                        color: #BABABA;
                    }
                }
            }
        }
    }

    td {
        border: 1px solid #BABABA;
        padding: 5px;
    }

    .td-category-container {
        padding:0;
        .td-category-text {
            font-weight:bold;
            padding:10px;
            background-color:#bababa;
        }
    }

    .td-image-container {
        text-align:center;
        overflow:hidden;
        position:relative;

        a {
            position:absolute;
            top:5px;
            right:5px;
        }

        img {
            width: 250px;
        }
    }

    .td-center-18 {
        text-align:center;
        font-size:18px;
    }

    .td-center-18-bold {
        padding: 15px 5px;
        font-size: 18px;
        font-weight: bold;
        text-align:center;
    }

    a {
        color: inherit;
        text-decoration: inherit;
        &:hover {
            opacity: 0.8;
        }
    }

    tr {

        background-color: $white;
    }

    tr.is-cancelled {
        background-color: rgba(241, 95, 95, 0.2);
        td {
            background-color: rgba(241, 95, 95, 0.2);
            color: red; 
        }
    }

    .tr-price-form {
        background-color:rgba(186, 186, 186, 0.3);
    }

    tbody {
        tr {
            &:hover {
                background-color: #bababa4d;
            }
        }
    }

    .center {
        text-align: center;
        width: auto;
    }

    .left {
        text-align: left;
        width: auto;
    }

    .right {
        text-align: right;
        width: auto;
    }

    tr.total {
        td {
            font-size: 18px;
            padding: 15px 5px;
        }
    }

    .table-grip-move {
        cursor: pointer;
        opacity: 1;
        color: $black;
        transition: opacity .3s, color .3s;
        &:hover {
            opacity: 0.9;
            color: #bababa;
            transition: opacity .3s, color .3s;
        }
    }

    .form-table-btn {
        padding:0;
        margin:0;
        button {
            border: 0;
            color: $black;
            padding:0;
            background-color: transparent;
            margin:0;
        
            &:hover {
                opacity: 0.8;
            }
        }
    }

    th.th-stt-command {
        background-color: rgba(233, 162, 68, 0.4);
    }
    th.th-stt-command-diff {
        background-color: rgba(45, 101, 185, 0.4);
    }

    td.stt-invoice {
        color: #b68c4d;
    }

    td.marge-positive {
        color: #6193df;
    }
    td.marge-negative {
        color: #c44646;
    }
    td {
        a.service-image-remove {
            position:absolute;
            top:5px;
            right:5px;
        }
    }
}

.table-ball {
    width: 25px;
    height: 25px;
    position: relative;
    margin: auto;
    border-radius: 3px;
}

.table-ball-quotation, 
.table-ball-grey {
    background-color: #BABABA;
}

.table-ball-invoice,
.table-ball-project,
.table-ball-orange {
    background-color: #e49631;
}

.table-ball-blue {
    background-color: #31abe4;
}

.table-ball-invoice-close,
.table-ball-project-close,
.table-ball-green {
    background-color: #7af04b;
}

.table-ball-cancelled, 
.table-ball-red {
    background-color: #eb4832;
}

.option-table-container {

    position: relative;
    display:flex;
    align-items:center;
    justify-content: flex-end;
    padding: 20px 0 0 0;
    gap:10px;

    a.btn {
        width: auto;
        margin: 0 0 0 0;
        white-space: nowrap;
    }

    .btn-red {
        background-color: #e96b6b;
        border:1px solid #e96b6b;
    }

    form {
        padding: 0 0 0 5px; 
        width: auto;

        button[type=submit] {
            width: auto;
            white-space: nowrap;
        }
    }
}

.table-paginate {
    display:flex;
    gap:5px;
    justify-content:flex-end;
    margin-top:5px;
    align-items:center;

    .paginate-item-per-page {
        display:flex;
        select {
            padding: 5px;
            font-size: $fontsize;
        }

        button {
            padding : 5px;
            font-size: $fontsize;
        }
    }

    .paginate-pages {
        width:100%;
        display:flex;
        gap:5px;  
        justify-content:flex-end;
        flex-wrap:wrap;
        align-items:center;

        .paginate-pages-info {
            padding: 0 5px;
        }

        form {
            button.page-btn {
                border:1px solid $black;
                padding:5px;
                background:$white;
                font-size: 11px;
                color:$black;
                font-weight: 400;
            }
            button.page-btn-current {
                font-size: $fontsize;
                font-weight: 900;
            }
        }
    }
}


a {
    color: inherit;
    text-decoration: none;
    opacity: 1;
    transition: opacity .3s;
    &:hover {
        opacity: 0.7;
        transition: opacity .3s;
    }
}

#install_button {
    position: relative;
    z-index: 99999;
}

.tooltip {
    position: absolute;
    padding: 10px 20px;
    text-align: center;
    font-size: 13px;
    z-index: 70000;
    top: 0;
    background-color: #FFFFFF;
    border: 1px solid #333333;
    border-left: 3px solid #333333;
    display: inline;
    -webkit-box-shadow: -1px 7px 12px 2px rgba(0,0,0,0.33); 
    box-shadow: -1px 7px 12px 2px rgba(0,0,0,0.33);
    font-weight: 200;
    text-transform: uppercase;
    /*border-radius: 4px;*/
}

.login-container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;

    .login-main {
        position: relative;
        width: 20%;
        height: auto;
        a {
            display: block;
            margin: auto;
            text-align: center;
        }
    }
}

.breadcrumb-container {
    position:relative;
    padding:10px 0;
    display:flex;
    align-items:center;
    gap: 5px;
    flex-wrap:wrap;
    text-transform: uppercase;
    border-bottom: 1px dashed #bababa;
    width:100%;
    margin-bottom:10px;

    .breadcrumb-items {
        width: calc(70% - 5px);
        display:flex;
        align-items:center;
        gap: 5px;
        flex-wrap:wrap;
    }

    .breadcrumb-quotation-name {
        position:relative;
        font-size: 18px;
        font-weight: 100;
        text-align:right;
        width:30%;
    }
}

.maintenance-mode-container {
    z-index:888;
    position:absolute;
    top:3px;
    left:5px;
}

.main-table-list-label {
    position:relative;
    font-size:32px;
    font-weight:100;
    border-bottom:1px dashed #bababa;
    padding:10px 0;
    display:flex;
    align-items:center;
    gap:10px;
    margin-bottom:10px;
    justify-content: flex-end;
}

.flash-message {
    position: fixed;
    top:0;
    z-index:99999;
    left:0;
    right:0;
    text-align: center;
    background-color: #FFFFFF;
    padding:10px;
    font-size:16px;
    overflow:hidden;
    width:100%;
    font-weight: bold;

    .flash-message-text {
        z-index: 1;
    }

    .flash-message-close {
        cursor:pointer;
        position:absolute;
        right:5px;
        padding:5px;
        top:5px;
        z-index: 2;
    }
}

.flash-notice {
    background-color: #eed6a3;
    color:#c49b45;
}

*, *:before, *:after {
    box-sizing: border-box;
}
.project-media-container {
    display:flex;
    flex-wrap:wrap;
    align-items:center;

    .project-media-item {
        width:20%;
        text-align:center;
        position:relative;
        overflow:hidden;

        a {
            display: block;
            color:inherit;
        }

        a.project-media-trash {
            color:inherit;
            position:absolute;
            top:10px;
            right:15px;
            z-index:2;
            display:block;
            padding: 5px;
            background-color:$white;
            border:1px solid $black;
        }

        img {
            border-radius:4px;
            border:1px solid $black;
            width:95%;
        }


        .project-document-type {
            display: flex;
            align-items: center;
            justify-content: center;
            /*border: 1px dotted grey;*/
            font-size: 26px;
            font-weight: bold;
            text-transform: uppercase;
            

            .document-type-pdf {
                width: 95%;
                height: 200px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 2px solid red;
                color: red;
            }

            .document-type-xls, .document-type-xlsx {
                width: 95%;
                height: 200px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 2px solid #2f9940;
                color: #2f9940;
            }

            .document-type-csv {
                width: 95%;
                height: 200px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 2px solid #38c44f;
                color: #38c44f;
            }

            .document-type-zip {
                width: 95%;
                height: 200px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 2px solid #996a35;
                color: #996a35;
            }

            .document-type-doc, .document-type-docx {
                width: 95%;
                height: 200px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 2px solid #2f5199;
                color: #2f5199;
            }

            .document-type-jpg, .document-type-jpeg {
                width: 95%;
                height: 200px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 2px solid #772f99;
                color: #772f99;
            }

            .document-type-png {
                width: 95%;
                height: 200px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 2px solid #9654b4;
                color: #9654b4;
            }
        }
    }
}
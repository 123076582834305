.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 7777;
    background-color: rgba(0, 0, 0, 0.5);

    display: none;

    .modal-container {

        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        display: flex;
        align-items: center;
        justify-content: center;
        

        .modal-header {
            position: relative;
            display: flex;
            align-items: center;

            .modal-header-close {
                position: absolute;
                right:0;
                top:0;
                width: auto;
                padding: 5px 5px;
                background-color: $black;
                color: $white;
                cursor:pointer;
                font-size: 16px;
            }

            .modal-header-title {
                width: 100%;
                font-weight: bold;
                padding: 20px 10px;
                font-size: 30px;
                text-align: center;

                &::first-letter {
                    text-transform: uppercase;
                }
            }

            
        }

        .modal-content {
            position: relative;
            margin-top: 20px;
            text-align: center;

            .modal-content-flex-btn {
                display: flex;
                position: relative;
                padding: 10px;

                button {
                    margin: 3px;
                    width: 100%;
                    min-width: inherit;
                    max-width: inherit;
                }
            }

        }

        .modal-confirm {
            width: 400px;
            min-height: 100px;
            background-color: $white;
            border: 0;
            border-radius: 3px 0 3px 3px;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
        }

        .modal-how-it-works {
            width: 700px;
            min-height: 700px;
            max-height: 700px;
            overflow-x: hidden;
            overflow-y: auto;
            background-color: $white;
            border: 0;
            border-radius: 3px 0 3px 3px;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;

            .how-it-works-ul {
                list-style: none;
                margin:0;
                padding:0;

                li {
                    padding: 15px 5px;
                    font-size: 28px;
                    margin: 10px;
                    border:1px solid #bababa;
                    display: flex;
                    align-items: center;
                    gap:10px;
                    justify-content: center;

                    .number {
                        width: 40px;
                        border: 1px solid #bababa;
                    }
                    .text {
                        width: 100%;
                        text-align: center;
                    }
                }
            }
        }
    }
}

#modal_how_it_works {
    //display: block;
}
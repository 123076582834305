.weather-container {
    position:relative;
    margin-top: 20px;

    .weather-container-col {
        display:flex;
        gap:10px;

        .weather-col-today {
            width:65%;
            max-height:75vh;
            overflow:auto;
            text-align:center;
            display:flex;
            align-items:center;
            justify-content:center;
            flex-direction:column; 
            

            .col-today-item {
                border:1px solid #bababa;
                width:100%; 
                padding:10px;
            }
        }

        .weather-col-other-today {
            border:1px solid #bababa;
            width:35%;
            max-height:75vh;
            overflow:auto;
            text-align:center;
            padding:10px;
            display: flex;
            flex-direction: column;
        }

        .weather-item {
            border:1px solid $black;
            margin: 5px 0;
            background-color:#bababa; 
            width:100%;

            .weather-item-city {
                border-bottom:1px solid $black;
                padding:10px 5px;
            }

            .weather-item-icon {
                position: relative;
                padding: 10px 5px;
            }

            .weather-item-description {
                font-size: 22px;
                font-weight: bold;
                padding: 10px 5px;
            }

            .weather-item-wind {
                padding:10px 5px;
                text-align:right;
            }

            .weather-item-date {
                border-top:1px solid $black;
                padding:10px 5px;
            }
        }

        .weather-today-items-min {
            display:flex;
            gap: 10px;

            .weather-item-description {
                font-size: $fontsize;
                font-weight: normal;
                padding: 10px 5px;
            }
        }

        .weather-items-other {
            display:flex;
            flex-wrap:wrap;
            gap:5px;
            padding:0;
            justify-content:center;
            margin-bottom: 20px;

            .weather-item {
                width: 49%;
                margin:0;
            }
        }
    }
}
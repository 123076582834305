form {
    position: relative;
    padding: 0 0;
}

label {
    display: block;
    padding: 0 0 0 0;
    margin: 10px 0 0 0;
    //font-size: 20px;

    span {
        color: #e63e3e;
    }

    &:first-letter {
        text-transform: uppercase;
    }
}

label.label-file {
    position: relative;
    overflow: hidden;
    border: 1px solid #BABABA;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: repeating-linear-gradient(120deg, white, white 35px, #f8f8f8 35px, #f8f8f8 70px);

    input[type=file] {
        position:absolute;
        z-index: -1;
        opacity: 0.2;
        width: auto;
    }
}

input, select, textarea {
    //font-family: 'Open Sans Condensed', sans-serif;
    font-family: "Roboto Condensed", sans-serif;
    border: 1px solid #BABABA;
    border-radius: 0;
    padding: 10px;
    font-size: $fontsize;
    color: $black;
    width:100%;
    background-color: $white;
    max-width: 100%;
    min-width: 100%;
}

input[type=checkbox] {
    width: auto;
    max-width: inherit;
    min-width: inherit;
}

button,
a.btn {
    //font-family: 'Open Sans Condensed', sans-serif;
    font-family: "Roboto Condensed", sans-serif;
    text-transform: uppercase;
    max-width: 100%;
    background-color: $black;
    border: 1px solid $black;
    border-radius: 0;
    color: $white;
    cursor: pointer;
    font-weight: bold;
    display:block;
    text-decoration: inherit;
    padding: 10px;
    opacity: 1;
    width:100%;
    //font-size: 16px;
    font-size: $fontsize;
    transition: opacity .3s;

    &:hover {
        opacity: 0.8;
        transition: opacity .3s;
    }
}

body.theme-blue {
    button,
    a.btn {
        //font-family: 'Open Sans Condensed', sans-serif;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
        max-width: 100%;
        background-color: #0390fc;
        border: 1px solid #0390fc;
        border-radius: 0;
        color: $white;
        cursor: pointer;
        font-weight: bold;
        display:block;
        text-decoration: inherit;
        padding: 10px;
        opacity: 1;
        width:100%;
        //font-size: 16px;
        font-size: $fontsize;
        transition: opacity .3s;

        &:hover {
            opacity: 0.8;
            transition: opacity .3s;
        }
    }
}

body.theme-red {
    button,
    a.btn {
        //font-family: 'Open Sans Condensed', sans-serif;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
        max-width: 100%;
        background-color: #e40909;
        border: 1px solid #e40909;
        border-radius: 0;
        color: $white;
        cursor: pointer;
        font-weight: bold;
        display:block;
        text-decoration: inherit;
        padding: 10px;
        opacity: 1;
        width:100%;
        //font-size: 16px;
        font-size: $fontsize;
        transition: opacity .3s;

        &:hover {
            opacity: 0.8;
            transition: opacity .3s;
        }
    }
}

body.theme-yellow {
    button,
    a.btn {
        //font-family: 'Open Sans Condensed', sans-serif;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
        max-width: 100%;
        background-color: #b8ac0b;
        border: 1px solid #b8ac0b;
        border-radius: 0;
        color: $white;
        cursor: pointer;
        font-weight: bold;
        display:block;
        text-decoration: inherit;
        padding: 10px;
        opacity: 1;
        width:100%;
        //font-size: 16px;
        font-size: $fontsize;
        transition: opacity .3s;

        &:hover {
            opacity: 0.8;
            transition: opacity .3s;
        }
    }
}

body.theme-orange {
    button,
    a.btn {
        //font-family: 'Open Sans Condensed', sans-serif;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
        max-width: 100%;
        background-color: #b8810b;
        border: 1px solid #b8810b;
        border-radius: 0;
        color: $white;
        cursor: pointer;
        font-weight: bold;
        display:block;
        text-decoration: inherit;
        padding: 10px;
        opacity: 1;
        width:100%;
        //font-size: 16px;
        font-size: $fontsize;
        transition: opacity .3s;

        &:hover {
            opacity: 0.8;
            transition: opacity .3s;
        }
    }
}

body.theme-purple {
    button,
    a.btn {
        //font-family: 'Open Sans Condensed', sans-serif;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
        max-width: 100%;
        background-color: #8f2fa7;
        border: 1px solid #8f2fa7;
        border-radius: 0;
        color: $white;
        cursor: pointer;
        font-weight: bold;
        display:block;
        text-decoration: inherit;
        padding: 10px;
        opacity: 1;
        width:100%;
        //font-size: 16px;
        font-size: $fontsize;
        transition: opacity .3s;

        &:hover {
            opacity: 0.8;
            transition: opacity .3s;
        }
    }
}

body.theme-green {
    button,
    a.btn {
        //font-family: 'Open Sans Condensed', sans-serif;
        font-family: "Roboto Condensed", sans-serif;
        text-transform: uppercase;
        max-width: 100%;
        background-color: #37a33d;
        border: 1px solid #37a33d;
        border-radius: 0;
        color: $white;
        cursor: pointer;
        font-weight: bold;
        display:block;
        text-decoration: inherit;
        padding: 10px;
        opacity: 1;
        width:100%;
        //font-size: 16px;
        font-size: $fontsize;
        transition: opacity .3s;

        &:hover {
            opacity: 0.8;
            transition: opacity .3s;
        }
    }
}

input[type=color] {
    width: 50px;
    height: 30px;
    max-width: inherit;
    min-width: inherit;
    padding: 0;
}

textarea{
    min-height: 100px;
}

.custom-field-container {
    display: flex;
    gap: 5px;
    align-items: center;
    position: relative;
    margin: 5px 0;

    .custom-field-item {
        width: 100%;
    }

    .custom-field-remove {
        cursor: pointer;
        color: $black;
        transition: color .3s;
        &:hover {
            color: red;
            transition: color .3s;
        }
    }
}

.custom-field-add-container {
    display: flex;
    margin: 10px 0;

    .custom-field-add-btn {
        border: 1px solid #bababa;
        padding: 5px;
        cursor: pointer;
    }
}

.quotation-linked-other-file-container {

    position: relative;
    border-top: 1px dashed #bababa;
    margin-top: 50px;
    padding: 50px;

    .quotation-linked-other-file-title {
        font-size: 22px;
        position:relative;
        font-weight: 100;
    }

    .quotation-linked-other-file {
        position:relative;
        padding:20px;
        margin: 10px 0;
        border:1px solid #bababa;
        display:flex;
        align-items:center;
        gap:10px;

        .quotation-linked-other-file-icon {
            width:50px;
            text-align:center;
            font-size:32px;
            position:relative;
        }

        .quotation-linked-other-file-text {
            position:relative;
            padding:3px;
        }
    }
}

.command-subcontractor-unavailable-notice {
    position:relative;
    padding:10px;
}

.command-subcontractor-form {

    position: relative;

    .command-subcontractor-lot-checkbox-all {
        display:flex;
        align-items:center;
        gap:5px;  
    }

    .command-subcontractor-lot-checkbox-item {
        display:flex;
        align-items:center;
        gap:5px;
        padding:5px 0;
        margin:5px 0;
    }
}

.command-subcontractor-list-container {
    position:relative;
    margin-top:30px;

    .command-subcontractor-list-label {
        position:relative;
        padding:5px 0;
        font-size:24px;
    }

    .command-subcontractor-list-item-container {
        margin:10px;
        border:1px solid #bababa;
        padding:10px;
        background-color:#eeeeee;

        .command-subcontractor-list-item-name {
            position:relative;
            padding:5px;
            font-size:18px;  
        }

        table.command-subcontractor-table-lot {

            .command-subcontractor-col-40 {
                padding:5px;
                width:40px;
                text-align:center;
            }
            .command-subcontractor-col-100 {
                padding:5px;
                width:100px;
                text-align:center;
            }

            .command-subcontractor-col-auto {
                padding:5px;
            }

            .command-subcontractor-col-total-txt {
                font-size:18px;
                font-weight:bold;
                text-align:center;
            }
            .command-subcontractor-col-total-value {
                padding:5px;
                width:100px;
                text-align:center;
                font-size: 18px;
            }
        }

        table.command-subcontractor-table-data {

            .command-subcontractor-data-150 {
                border-top:0;
                padding:5px;
                width:150px;
                text-align:center;
            }

            .command-subcontractor-data-auto {
                border-top:0;
                padding:5px;
                width:auto;
            }

            input[type="number"], select {
                box-sizing:border-box;
                width:100%;
                max-width:inherit;
                min-width:inherit;
            }
            input[type="file"] {
                border:0;
                background-color:transparent;
                padding: 0;
            }

            .notice-input-file {
                padding:0;
            }

            button {
                box-sizing:border-box;
                width:30px;
                border:0;
                display:flex;
                align-items:center;
                justify-content:center;
                margin:auto; 
            }
        }

        .command-subcontractor-invoice-label {
            position:relative;
            font-size:18px;
            margin-top:10px; 
        }
    }
}

#society_search_api_gouv_result_total {
    position:relative;
    text-align: center;
    padding: 10px 0;
}
#society_search_api_gouv_result_paginate {
    display:flex;
    gap:5px;
    font-size:13px;
    flex-wrap:wrap;
    padding:10px 0;
    justify-content:center;
}
.society-search-api-gouv-result-paginate-item {
    padding:5px;
    border:1px solid #bababa;
    cursor:pointer; 
}
.society-search-api-gouv-result-paginate-item-active {
    border:1px solid #555555;
    background-color:#555555;
    color:#FFFFFF;
}
.society-search-api-gouv-result-item {
    position:relative;
    margin:10px 0;
    border:1px solid #bababa;
    font-size:14px;
    padding:10px;
    cursor:pointer;
    opacity:1;
    transition: opacity .3s;

    .api-gouv-result-item-select {
        position:relative;
        padding:5px 0;
        visibility: hidden;
        text-align: right;
    }
    &:hover {
        opacity:0.6;
        transition: opacity .3s;
        .api-gouv-result-item-select {
            visibility: visible;
        }
    }
}
.api-gouv-result-item {
    position:relative;
    padding:5px 0;
}
.api-gouv-result-item-name {
    font-weight:bold;
}

.society-logo-form {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 90%;
    width:200px;
    height: 200px;
    border:1px solid #bababa;
    margin-bottom:10px;
}

#society_map {
    position:relative;
    width:50%;
    height:800px;
    border:1px solid #bababa;
}

input[disabled="disabled"] {
    background-color: #dddddd;
}